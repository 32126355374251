import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './StockReports.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://kooplify.nw.r.appspot.com';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const StockReports = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const fetchReports = async () => {
    try {
      setLoading(true);
      setError(null);
      if (!dateRange.start || !dateRange.end) {
        throw new Error(t('select_both_dates'));
      }
      if (!cooperativeId) {
        throw new Error(t('no_cooperative_id'));
      }
      const response = await axios.get(`${API_BASE_URL}/api/stock-reports`, {
        params: { ...dateRange, cooperativeId }
      });
      setReports(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateRange(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchReports();
  };

  if (!cooperativeId) return <div className={styles.error}>{t('no_cooperative_id')}</div>;

  return (
    <div className={styles.container}>
      <h2>{t('stock_reports')}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="date"
          name="start"
          value={dateRange.start}
          onChange={handleDateChange}
          required
        />
        <input
          type="date"
          name="end"
          value={dateRange.end}
          onChange={handleDateChange}
          required
        />
        <button type="submit">{t('generate_report')}</button>
      </form>
      {loading && <div className={styles.loading}>{t('loading')}</div>}
      {error && <div className={styles.error}>{error}</div>}
      {!loading && !error && reports.length > 0 && (
        <table className={styles.reportTable}>
          <thead>
            <tr>
              <th>{t('item_name')}</th>
              <th>{t('opening_stock')}</th>
              <th>{t('stock_received')}</th>
              <th>{t('stock_issued')}</th>
              <th>{t('closing_stock')}</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report._id || report.itemName}>
                <td>{report.itemName}</td>
                <td>{report.openingStock}</td>
                <td>{report.stockReceived}</td>
                <td>{report.stockIssued}</td>
                <td>{report.closingStock}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StockReports;