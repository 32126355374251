import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './InventoryTransactions.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://kooplify.nw.r.appspot.com';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const InventoryTransactions = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newTransaction, setNewTransaction] = useState({
    date: '',
    type: '',
    itemName: '',
    quantity: 0,
    cooperativeId: cooperativeId
  });

  useEffect(() => {
    if (cooperativeId) {
      fetchTransactions();
    }
  }, [cooperativeId]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/inventory-transactions`, {
        params: { cooperativeId }
      });
      setTransactions(response.data);
      setLoading(false);
    } catch (err) {
      setError(t('failed_fetch_transactions'));
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTransaction({ ...newTransaction, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/api/inventory-transactions`, {
        ...newTransaction,
        cooperativeId
      });
      fetchTransactions();
      setNewTransaction({ date: '', type: '', itemName: '', quantity: 0, cooperativeId });
    } catch (err) {
      setError(t('failed_add_transaction'));
    }
  };

  if (!cooperativeId) return <div className={styles.error}>{t('no_cooperative_id')}</div>;
  if (loading) return <div className={styles.loading}>{t('loading')}</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h2>{t('inventory_transactions')}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="date"
          name="date"
          value={newTransaction.date}
          onChange={handleInputChange}
          required
        />
        <select
          name="type"
          value={newTransaction.type}
          onChange={handleInputChange}
          required
        >
          <option value="">{t('select_type')}</option>
          <option value="receipt">{t('receipt')}</option>
          <option value="issue">{t('issue')}</option>
        </select>
        <input
          type="text"
          name="itemName"
          value={newTransaction.itemName}
          onChange={handleInputChange}
          placeholder={t('item_name')}
          required
        />
        <input
          type="number"
          name="quantity"
          value={newTransaction.quantity}
          onChange={handleInputChange}
          placeholder={t('quantity')}
          required
        />
        <button type="submit">{t('add_transaction')}</button>
      </form>
      <table className={styles.transactionTable}>
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('transaction_type')}</th>
            <th>{t('item_name')}</th>
            <th>{t('quantity')}</th>
            <th>{t('balance')}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{new Date(transaction.date).toLocaleDateString()}</td>
              <td>{t(transaction.type)}</td>
              <td>{transaction.itemName}</td>
              <td>{transaction.quantity}</td>
              <td>{transaction.balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTransactions;