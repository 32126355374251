import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const InvoiceGeneration = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [newInvoice, setNewInvoice] = useState({
    date: '',
    invoiceNumber: '',
    clientName: '',
    items: '',
    totalAmount: '',
    dueDate: '',
    status: 'unpaid'
  });
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchInvoices = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/invoices`, {
        params: { cooperativeId }
      });
      setInvoices(response.data);
    } catch (error) {
      setError(t('error_fetching_invoices'));
      console.error('Error fetching invoices:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchInvoices();
    }
  }, [cooperativeId, fetchInvoices]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingInvoice) {
      setEditingInvoice(prev => ({ ...prev, [name]: value }));
    } else {
      setNewInvoice(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingInvoice ? editingInvoice : newInvoice;
      const method = editingInvoice ? 'put' : 'post';
      const url = editingInvoice 
        ? `${API_BASE_URL}/api/invoices/${editingInvoice._id}`
        : `${API_BASE_URL}/api/invoices`;

      const requestData = { ...data, cooperativeId };

      await axios[method](url, requestData);

      setSuccess(editingInvoice ? t('invoice_updated_successfully') : t('invoice_added_successfully'));
      fetchInvoices();
      setNewInvoice({
        date: '',
        invoiceNumber: '',
        clientName: '',
        items: '',
        totalAmount: '',
        dueDate: '',
        status: 'unpaid'
      });
      setEditingInvoice(null);
    } catch (error) {
      setError(t('error_saving_invoice'));
      console.error('Error saving invoice:', error.response?.data || error.message);
    }
  };

  const handleEdit = (invoice) => {
    setEditingInvoice(invoice);
    setNewInvoice(invoice);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/invoices/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('invoice_deleted_successfully'));
        fetchInvoices();
      } catch (error) {
        setError(t('error_deleting_invoice'));
        console.error('Error deleting invoice:', error);
      }
    }
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      await axios.patch(`${API_BASE_URL}/api/invoices/${id}/status`, { status: newStatus, cooperativeId });
      setSuccess(t('invoice_status_updated_successfully'));
      fetchInvoices();
    } catch (error) {
      setError(t('error_updating_invoice_status'));
      console.error('Error updating invoice status:', error);
    }
  };

  

  const handleGeneratePDF = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/invoices/pdf`, {
        params: { cooperativeId },
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSuccess(t('pdf_generated_successfully'));
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError(t('error_generating_pdf'));
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('invoice_generation')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <input
            type="date"
            name="date"
            value={newInvoice.date}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="invoiceNumber"
            value={newInvoice.invoiceNumber}
            onChange={handleInputChange}
            placeholder={t('invoice_number')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="clientName"
            value={newInvoice.clientName}
            onChange={handleInputChange}
            placeholder={t('client_name')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="items"
            value={newInvoice.items}
            onChange={handleInputChange}
            placeholder={t('items_services')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="totalAmount"
            value={newInvoice.totalAmount}
            onChange={handleInputChange}
            placeholder={t('total_amount')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="date"
            name="dueDate"
            value={newInvoice.dueDate}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          {t('generate_invoice')}
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">{t('date')}</th>
              <th className="px-4 py-2">{t('invoice_number')}</th>
              <th className="px-4 py-2">{t('client_name')}</th>
              <th className="px-4 py-2">{t('items_services')}</th>
              <th className="px-4 py-2">{t('total_amount')}</th>
              <th className="px-4 py-2">{t('due_date')}</th>
              <th className="px-4 py-2">{t('status')}</th>
              <th className="px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice._id} className="border-b">
                <td className="px-4 py-2">{new Date(invoice.date).toLocaleDateString()}</td>
                <td className="px-4 py-2">{invoice.invoiceNumber}</td>
                <td className="px-4 py-2">{invoice.clientName}</td>
                <td className="px-4 py-2">{invoice.items}</td>
                <td className="px-4 py-2">${invoice.totalAmount}</td>
                <td className="px-4 py-2">{new Date(invoice.dueDate).toLocaleDateString()}</td>
                <td className="px-4 py-2">{t(invoice.status)}</td>
                <td className="px-4 py-2">
                  <select 
                    value={invoice.status} 
                    onChange={(e) => handleStatusUpdate(invoice._id, e.target.value)}
                    className="mb-2 p-1 border rounded"
                  >
                    <option value="unpaid">{t('unpaid')}</option>
                    <option value="paid">{t('paid')}</option>
                    <option value="overdue">{t('overdue')}</option>
                  </select>
                  <button 
                    onClick={() => handleGeneratePDF(invoice._id)}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    {t('generate_pdf')}
                  </button>
                  <button 
                    onClick={() => handleDelete(invoice._id)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}; 
export default InvoiceGeneration;