// SupplierManagement.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './SupplierManagement.module.css';

const SupplierManagement = () => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newSupplier, setNewSupplier] = useState({
    name: '',
    contact: '',
    items: '',
  });

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://kooplify.nw.r.appspot.com/api/suppliers');
      setSuppliers(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch supplier data');
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewSupplier({ ...newSupplier, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://kooplify.nw.r.appspot.com/api/suppliers', newSupplier);
      fetchSuppliers();
      setNewSupplier({ name: '', contact: '', items: '' });
    } catch (err) {
      setError('Failed to add new supplier');
    }
  };

  const handleDelete = async (supplierId) => {
    try {
      await axios.delete(`https://kooplify.nw.r.appspot.com/api/suppliers/${supplierId}`);
      fetchSuppliers();
    } catch (err) {
      setError('Failed to delete supplier');
    }
  };

  if (loading) return <div className={styles.loading}>{t('loading')}</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h2>{t('supplier_management')}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          name="name"
          value={newSupplier.name}
          onChange={handleInputChange}
          placeholder={t('supplier_name')}
          required
        />
        <input
          type="text"
          name="contact"
          value={newSupplier.contact}
          onChange={handleInputChange}
          placeholder={t('contact_information')}
          required
        />
        <input
          type="text"
          name="items"
          value={newSupplier.items}
          onChange={handleInputChange}
          placeholder={t('items_supplied')}
          required
        />
        <button type="submit">{t('add_supplier')}</button>
      </form>
      <table className={styles.supplierTable}>
        <thead>
          <tr>
            <th>{t('supplier_name')}</th>
            <th>{t('contact_information')}</th>
            <th>{t('items_supplied')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier) => (
            <tr key={supplier._id}>
              <td>{supplier.name}</td>
              <td>{supplier.contact}</td>
              <td>{supplier.items}</td>
              <td>
                <button onClick={() => handleDelete(supplier._id)} className={styles.deleteButton}>
                  {t('delete')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SupplierManagement;