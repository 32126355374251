import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Line, Bar } from 'react-chartjs-2';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const SalesBook = () => {
  const { cooperativeId } = useCooperative();
  console.log('Current cooperativeId:', cooperativeId);

  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    productSold: '',
    quantity: '',
    price: '',
    customerName: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchEntries = useCallback(async () => {
    console.log('fetchEntries called, cooperativeId:', cooperativeId);
    if (!cooperativeId) {
      console.log('No cooperativeId, setting error');
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      console.log('Making API request to fetch entries');
      const response = await axios.get(`${API_BASE_URL}/api/sales`, {
        params: { cooperativeId }
      });
      console.log('API response received:', response.data);
      setEntries(response.data);
      console.log('Entries state updated');
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    console.log('useEffect triggered, cooperativeId:', cooperativeId);
    if (cooperativeId) {
      console.log('Calling fetchEntries from useEffect');
      fetchEntries();
    } else {
      console.log('No cooperativeId available, skipping fetchEntries');
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/sales/${editingEntry._id}`
        : `${API_BASE_URL}/api/sales`;

      // Include cooperativeId in the data
      const requestData = { 
        ...data, 
        cooperativeId,
        quantity: Number(data.quantity),
        price: Number(data.price)
      };

      await axios[method](url, requestData);

      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchEntries();
      setNewEntry({ date: '', productSold: '', quantity: '', price: '', customerName: '' });
      setEditingEntry(null);
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry({
      ...entry,
      date: new Date(entry.date).toISOString().split('T')[0]
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/sales/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const generateSalesAnalytics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/sales/analytics`, {
        params: { cooperativeId }
      });
      setAnalytics(response.data);
    } catch (error) {
      setError(t('error_generating_analytics'));
      console.error('Error generating analytics:', error);
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };


  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{t('sales_book')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <input
          type="date"
          name="date"
          value={newEntry.date}
          onChange={handleInputChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="productSold"
          value={newEntry.productSold}
          onChange={handleInputChange}
          placeholder={t('product_service_sold')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="quantity"
          value={newEntry.quantity}
          onChange={handleInputChange}
          placeholder={t('quantity')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="price"
          value={newEntry.price}
          onChange={handleInputChange}
          placeholder={t('price')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="customerName"
          value={newEntry.customerName}
          onChange={handleInputChange}
          placeholder={t('customer_name')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <button 
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          {t('add_entry')}
        </button>
      </form>
      <div className="overflow-x-auto mb-8">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="p-3 text-left">{t('date')}</th>
              <th className="p-3 text-left">{t('product_service_sold')}</th>
              <th className="p-3 text-left">{t('quantity')}</th>
              <th className="p-3 text-left">{t('price')}</th>
              <th className="p-3 text-left">{t('total_amount')}</th>
              <th className="p-3 text-left">{t('customer_name')}</th>
              <th className="p-3 text-left">{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {entries.map((entry) => (
              <tr key={entry._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="p-3">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="p-3">{entry.productSold}</td>
                <td className="p-3">{entry.quantity}</td>
                <td className="p-3">{entry.price}</td>
                <td className="p-3">{entry.totalAmount.toFixed(2)}</td>
                <td className="p-3">{entry.customerName}</td>
                <td className="p-3">
                  <button 
                    onClick={() => handleEdit(entry)}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2 transition duration-300"
                  >
                    {t('edit')}
                  </button>
                  <button 
                    onClick={() => handleDelete(entry._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded transition duration-300"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button 
        onClick={generateSalesAnalytics} 
        className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-8 transition duration-300"
      >
        {t('sales_analytics')}
      </button>
      {analytics && (
        <div className="space-y-8">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4">{t('sales_by_product')}</h3>
            <Bar
              data={{
                labels: analytics.salesByProduct.map(item => item._id),
                datasets: [
                  {
                    label: t('total_sales'),
                    data: analytics.salesByProduct.map(item => item.totalSales),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
              className="h-64"
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4">{t('sales_over_time')}</h3>
            <Line
              data={{
                labels: analytics.salesByDate.map(item => item._id),
                datasets: [
                  {
                    label: t('total_sales'),
                    data: analytics.salesByDate.map(item => item.totalSales),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
              className="h-64"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default SalesBook;