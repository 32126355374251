import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
  baseURL: 'https://kooplify.nw.r.appspot.com/api',
});

// Function to set the auth token for future requests
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

const LoginForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setLoginError(null);
    try {
      // Login request
      const loginResponse = await api.post('/users/login', {
        usernameOrEmail: data.username,
        password: data.password,
      });
  
      if (!loginResponse.data || !loginResponse.data.token) {
        throw new Error('Login successful but no access token received');
      }
  
      const token = loginResponse.data.token;
      setAuthToken(token);
  
      // Fetch user details
      try {
        const userResponse = await api.get('/users/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (!userResponse || !userResponse.data) {
          throw new Error('Failed to fetch user details');
        }
  
        const cooperativeId = userResponse.data.cooperativeId;
  
        if (cooperativeId == null) {
          throw new Error('Cooperative ID not found in user details');
        }
  
        // Store cooperative ID
        localStorage.setItem('cooperativeId', cooperativeId);
  
        // Navigate to dashboard
        navigate('/dashboard');
  
      } catch (userError) {
        console.error('Error fetching user details:', userError);
        throw new Error('Failed to fetch user details. Please try logging in again.');
      }
  
    } catch (error) {
      console.error('Error during login process:', error);
  
      if (error.response) {
        // Handle specific HTTP error responses
        switch (error.response.status) {
          case 404:
            setLoginError('User not found. Please check your credentials.');
            break;
          case 401:
            setLoginError('Invalid credentials. Please try again.');
            break;
          default:
            setLoginError(error.response.data?.message || 'An error occurred during login');
        }
      } else if (error.request) {
        setLoginError('No response from server. Please try again later.');
      } else {
        setLoginError(error.message || 'An error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const inputClass = "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500";
  const labelClass = "block text-sm font-medium text-gray-700 mb-1";
  const errorClass = "text-red-500 text-xs mt-1";
  const buttonClass = "w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <h2 className="text-2xl font-bold mb-4">{t('login') || 'Login'}</h2>
        
        <div>
          <label htmlFor="username" className={labelClass}>
            {t('username_or_email') || 'Username or Email'}
          </label>
          <input
            id="username"
            {...register("username", { required: true })}
            className={inputClass}
            placeholder={t('username_or_email_placeholder') || 'Enter your username or email'}
          />
          {errors.username && <span className={errorClass}>{t('field_required') || 'This field is required'}</span>}
        </div>
        
        <div>
          <label htmlFor="password" className={labelClass}>
            {t('password') || 'Password'}
          </label>
          <input
            id="password"
            type="password"
            {...register("password", { required: true })}
            className={inputClass}
            placeholder={t('password_placeholder') || 'Enter your password'}
          />
          {errors.password && <span className={errorClass}>{t('field_required') || 'This field is required'}</span>}
        </div>
        
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              {...register("rememberMe")}
              className="mr-2"
            />
            <span className="text-sm">{t('remember_me') || 'Remember me'}</span>
          </label>
          
          <a href="/forgot-password" className="text-sm text-blue-500 hover:text-blue-600">
            {t('forgot_password') || 'Forgot password?'}
          </a>
        </div>
        
        <ReCAPTCHA
          sitekey="6LduYhwqAAAAABAOHofOWENlfTJ6b8_9zhMa25L_"
          onChange={() => {}}
          onErrored={(error) => console.error('reCAPTCHA error:', error)}
        />
        
        {loginError && <div className="text-red-500 text-sm">{loginError}</div>}
        
        <button type="submit" className={buttonClass} disabled={isSubmitting}>
          {isSubmitting ? (t('logging_in') || 'Logging in...') : (t('login') || 'Login')}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;