import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import NotFound from './NotFound';
import Navigation from './Navigation';
import Notifications from './Notifications';
import Overview from './Overview';
import UserRolesPermissions from './UserRolesPermissions';
import UserProfiles from './UserProfiles';
import CooperativeProfile from './CooperativeProfile';
import MemberDirectory from './MemberDirectory';
import CashBook from './CashBook';
import DebtorsBook from './DebtorsBook';
import CreditorsBook from './CreditorsBook';
import MembersShares from './MembersShares';
import PurchaseBook from './PurchaseBook';
import SalesBook from './SalesBook';
import StockDocuments from './StockDocuments';
import IntangibleDocuments from './IntangibleDocuments';
import InvoiceGeneration from './InvoiceGeneration';
import DeliveryNote from './DeliveryNote';
import BalanceSheet from './BalanceSheet';
import IncomeStatement from './IncomeStatement';
import InventoryTracking from './InventoryTracking';
import InventoryTransactions from './InventoryTransactions';
import StockReports from './StockReports';
import StockAlerts from './StockAlerts';
import SupplierManagement from './SupplierManagement';

const PrivateRoute = ({ children, requiredRole = null }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('https://kooplify.nw.r.appspot.com/api/users/me', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setIsAuthenticated(true);
        setUserRole(response.data.role);
      } catch (error) {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

const Dashboard = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [cooperativeData, setCooperativeData] = useState(null);

  useEffect(() => {
    const fetchCooperativeData = async () => {
      try {
        const response = await axios.get('https://kooplify.nw.r.appspot.com/api/dashboard/overview', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setCooperativeData(response.data);
      } catch (error) {
        console.error('Error fetching cooperative data:', error);
      }
    };
    fetchCooperativeData();
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <Navigation />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <Routes>
              <Route index element={<PrivateRoute><Overview cooperativeData={cooperativeData} /></PrivateRoute>} />
              <Route path="user-management/roles" element={<PrivateRoute requiredRole="admin"><UserRolesPermissions /></PrivateRoute>} />
              <Route path="user-management/profiles" element={<PrivateRoute><UserProfiles /></PrivateRoute>} />
              <Route path="cooperative-management/profile" element={<CooperativeProfile />} />
              <Route path="cooperative-management/members" element={<MemberDirectory />} />
              <Route path="books/cash-book" element={<CashBook />} />
              <Route path="books/debtors-book" element={<DebtorsBook />} />
              <Route path="books/creditors-book" element={<CreditorsBook />} />
              <Route path="books/members-shares" element={<MembersShares />} />
              <Route path="books/purchase-book" element={<PurchaseBook />} />
              <Route path="books/sales-book" element={<SalesBook />} />
              <Route path="books/stock-documents" element={<StockDocuments />} />
              <Route path="books/intangible-documents" element={<IntangibleDocuments />} />
              <Route path="books/invoice-generation" element={<InvoiceGeneration />} />
              <Route path="books/delivery-note" element={<DeliveryNote />} />
              <Route path="books/balance-sheet" element={<BalanceSheet />} />
              <Route path="books/income-statement" element={<IncomeStatement />} />
              <Route path="inventory-management/tracking" element={<InventoryTracking />} />
              <Route path="inventory-management/transactions" element={<InventoryTransactions />} />
              <Route path="inventory-management/reports" element={<StockReports />} />
              <Route path="inventory-management/alerts" element={<StockAlerts />} />
              <Route path="inventory-management/suppliers" element={<SupplierManagement />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
        <aside className="hidden lg:block w-64 bg-white border-l border-gray-200 overflow-y-auto">
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-700 mb-4">{t('notifications')}</h2>
              <Notifications />
            </div>
          </aside>
        </div>
        
      </div>
    
  );
};

export default Dashboard;