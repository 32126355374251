import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Axios interceptor setup (same as in DebtorsBook)
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const CashBook = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    description: '',
    cashIn: '',
    cashOut: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [report, setReport] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchEntries = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/Cashbook`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchEntries();
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const cashIn = parseFloat(data.cashIn) || 0;
      const cashOut = parseFloat(data.cashOut) || 0;
      const balance = entries.length > 0
        ? (entries[entries.length - 1].balance || 0) + cashIn - cashOut
        : cashIn - cashOut;
  
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/Cashbook/${editingEntry._id}`
        : `${API_BASE_URL}/api/Cashbook`;
  
      const requestData = { 
        ...data, 
        cashIn, 
        cashOut, 
        balance, 
        cooperativeId 
      };
  
      await axios[method](url, requestData);
  
      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchEntries();
      setNewEntry({ date: '', description: '', cashIn: '', cashOut: '' });
      setEditingEntry(null);
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry({
      date: entry.date.split('T')[0],
      description: entry.description,
      cashIn: entry.cashIn,
      cashOut: entry.cashOut
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/Cashbook/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const generateReport = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/Cashbook/report`, {
        params: { cooperativeId }
      });
      setReport(response.data);
      setSuccess(t('report_generated_successfully'));
    } catch (error) {
      setError(t('error_generating_report'));
      console.error('Error generating report:', error);
    }
  };

  const downloadReport = () => {
    const token = localStorage.getItem('token'); // Retrieve the token from local storage
    if (!token) {
      setError(t('no_auth_token'));
      return;
    }
  
    // Use axios instead of window.open to make the request
    axios({
      url: `${API_BASE_URL}/api/Cashbook/report/download`,
      method: 'GET',
      responseType: 'blob', // Important for receiving binary data
      params: { cooperativeId },
      headers: {
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cashbook_report.pdf');
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      setError(t('error_downloading_report'));
      console.error('Error downloading report:', error);
    });
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };


  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">{t('cash_book')}</h2>
      
      <form onSubmit={handleSubmit} className="mb-8 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <input
            type="date"
            name="date"
            value={newEntry.date}
            onChange={handleInputChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            type="text"
            name="description"
            value={newEntry.description}
            onChange={handleInputChange}
            placeholder={t('description')}
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            type="number"
            name="cashIn"
            value={newEntry.cashIn}
            onChange={handleInputChange}
            placeholder={t('cash_in')}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            type="number"
            name="cashOut"
            value={newEntry.cashOut}
            onChange={handleInputChange}
            placeholder={t('cash_out')}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <button 
          type="submit"
          className="w-full md:w-auto px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          {editingEntry ? t('update_entry') : t('add_entry')}
        </button>
      </form>
  
      <div className="overflow-x-auto mb-8">
        <table className="w-full border-collapse">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-3 text-left font-semibold">{t('date')}</th>
              <th className="p-3 text-left font-semibold">{t('description')}</th>
              <th className="p-3 text-right font-semibold">{t('cash_in')}</th>
              <th className="p-3 text-right font-semibold">{t('cash_out')}</th>
              <th className="p-3 text-right font-semibold">{t('balance')}</th>
              <th className="p-3 text-center font-semibold">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={entry._id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="p-3">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="p-3">{entry.description}</td>
                <td className="p-3 text-right">{(entry.cashIn || 0).toFixed(2)}</td>
                <td className="p-3 text-right">{(entry.cashOut || 0).toFixed(2)}</td>
                <td className="p-3 text-right">{(entry.balance || 0).toFixed(2)}</td>
                <td className="p-3 text-center">
                  <button 
                    onClick={() => handleEdit(entry)}
                    className="px-3 py-1 bg-yellow-500 text-white rounded-md mr-2 hover:bg-yellow-600 transition duration-300 ease-in-out"
                  >
                    Edit
                  </button>
                  <button 
                    onClick={() => handleDelete(entry._id)}
                    className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      <button 
        onClick={generateReport} 
        className="w-full md:w-auto px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300 ease-in-out mb-4"
      >
        {t('generate_report')}
      </button>
  
      {report && (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-4">{t('report')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p><span className="font-semibold">{t('total_entries')}:</span> {report.totalEntries}</p>
            <p><span className="font-semibold">{t('total_cash_in')}:</span> {report.totalCashIn.toFixed(2)}</p>
            <p><span className="font-semibold">{t('total_cash_out')}:</span> {report.totalCashOut.toFixed(2)}</p>
            <p><span className="font-semibold">{t('net_balance')}:</span> {report.netBalance.toFixed(2)}</p>
          </div>
          <button 
            onClick={downloadReport}
            className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            {t('download_pdf')}
          </button>
        </div>
      )}
    </div>
  );
};

export default CashBook;