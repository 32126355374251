import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = `${process.env.REACT_APP_API_URL || 'https://kooplify.nw.r.appspot.com'}/api`;


// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const MembersShares = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    memberName: '',
    numberOfShares: '',
    shareValue: ''
  });
  const [memberTotals, setMemberTotals] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingEntry, setEditingEntry] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchEntries = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shares`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error);
    } finally {
      setIsLoading(false);
    }
  }, [cooperativeId, t]);

  const fetchMemberTotals = useCallback(async () => {
    if (!cooperativeId) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shares/member-totals`, {
        params: { cooperativeId }
      });
      setMemberTotals(response.data);
    } catch (error) {
      setError(t('error_fetching_member_totals'));
      console.error('Error fetching member totals:', error);
    } finally {
      setIsLoading(false);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchEntries();
      fetchMemberTotals();
    }
  }, [cooperativeId, fetchEntries, fetchMemberTotals]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(`${API_BASE_URL}/shares`, { ...newEntry, cooperativeId });
      setSuccess(t('entry_added_successfully'));
      fetchEntries();
      fetchMemberTotals();
      setNewEntry({ date: '', memberName: '', numberOfShares: '', shareValue: '' });
    } catch (error) {
      setError(t('error_adding_entry'));
      console.error('Error adding entry:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shares/search`, {
        params: { query: searchQuery, cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_searching_entries'));
      console.error('Error searching entries:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry({
      date: entry.date.split('T')[0],
      memberName: entry.memberName,
      numberOfShares: entry.numberOfShares,
      shareValue: entry.shareValue
    });
  };

  const handleUpdate = async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(`${API_BASE_URL}/shares/${editingEntry._id}`, { ...newEntry, cooperativeId });
      setSuccess(t('entry_updated_successfully'));
      fetchEntries();
      fetchMemberTotals();
      setEditingEntry(null);
      setNewEntry({ date: '', memberName: '', numberOfShares: '', shareValue: '' });
    } catch (error) {
      setError(t('error_updating_entry'));
      console.error('Error updating entry:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    if (window.confirm(t('confirm_delete'))) {
      setIsLoading(true);
      try {
        await axios.delete(`${API_BASE_URL}/shares/${id}`, {
          params: { cooperativeId }
        });
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
        fetchMemberTotals();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{t('members_shares')}</h2>
      <form onSubmit={editingEntry ? handleUpdate : handleSubmit} className="space-y-4 mb-8">
        <input
          type="date"
          name="date"
          value={newEntry.date}
          onChange={handleInputChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="memberName"
          value={newEntry.memberName}
          onChange={handleInputChange}
          placeholder={t('member_name')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="numberOfShares"
          value={newEntry.numberOfShares}
          onChange={handleInputChange}
          placeholder={t('number_of_shares')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="shareValue"
          value={newEntry.shareValue}
          onChange={handleInputChange}
          placeholder={t('share_value')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <div className="flex space-x-2">
          <button 
            type="submit"
            className="flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {editingEntry ? t('update_entry') : t('add_entry')}
          </button>
          {editingEntry && (
            <button 
              type="button" 
              onClick={() => setEditingEntry(null)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              {t('cancel')}
            </button>
          )}
        </div>
      </form>
      <div className="flex space-x-2 mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={t('search')}
          className="flex-grow p-2 border border-gray-300 rounded"
        />
        <button 
          onClick={handleSearch}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          {t('search')}
        </button>
      </div>
      <div className="overflow-x-auto mb-8">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="p-3 text-left">{t('date')}</th>
              <th className="p-3 text-left">{t('member_name')}</th>
              <th className="p-3 text-left">{t('number_of_shares')}</th>
              <th className="p-3 text-left">{t('share_value')}</th>
              <th className="p-3 text-left">{t('total_value')}</th>
              <th className="p-3 text-left">{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {entries.map((entry) => (
              <tr key={entry._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="p-3">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="p-3">{entry.memberName}</td>
                <td className="p-3">{entry.numberOfShares}</td>
                <td className="p-3">{entry.shareValue.toFixed(2)}</td>
                <td className="p-3">{entry.totalValue.toFixed(2)}</td>
                <td className="p-3">
                  <button 
                    onClick={() => handleEdit(entry)}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2 transition duration-300"
                  >
                    {t('edit')}
                  </button>
                  <button 
                    onClick={() => handleDelete(entry._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded transition duration-300"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white shadow-md rounded-lg p-4">
        <h3 className="text-xl font-semibold mb-4">{t('member_totals')}</h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="p-3 text-left">{t('member_name')}</th>
                <th className="p-3 text-left">{t('total_shares')}</th>
                <th className="p-3 text-left">{t('total_value')}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {memberTotals.map((total) => (
                <tr key={total._id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="p-3">{total._id}</td>
                  <td className="p-3">{total.totalShares}</td>
                  <td className="p-3">{total.totalValue.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default MembersShares;