import React from 'react';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();

  // This is just a placeholder.
  const notifications = [
    { id: 1, message: 'New member joined', time: '5 minutes ago' },
    { id: 2, message: 'Sales report ready', time: '1 hour ago' },
    { id: 3, message: 'Inventory low alert', time: '2 hours ago' },
  ];

  return (
    <div className="space-y-4">
      {notifications.map((notification) => (
        <div key={notification.id} className="bg-gray-50 p-3 rounded-lg shadow-sm">
          <p className="text-sm text-gray-800">{notification.message}</p>
          <p className="text-xs text-gray-500 mt-1">{notification.time}</p>
        </div>
      ))}
    </div>
  );
};

export default Notifications;