import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthButton from './AuthButton';
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              Kooplify
            </Link>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
            <NavLink to="/">{t('home')}</NavLink>
            <NavLink to="/about">{t('about')}</NavLink>
            <NavLink to="/features">{t('features')}</NavLink>
            <NavLink to="/contact">{t('contact')}</NavLink>
            <NavLink to="/dashboard">{t('Dashboard')}</NavLink>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
          <AuthButton />
            <NavLink
             to="/register"
             className="ml-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
             {t('register')}
            </NavLink>
            <div className="ml-8 flex space-x-2">
              <LanguageButton lang="en" currentLang={i18n.language} changeLanguage={changeLanguage} />
              <LanguageButton lang="fr" currentLang={i18n.language} changeLanguage={changeLanguage} />
              <LanguageButton lang="rw" currentLang={i18n.language} changeLanguage={changeLanguage} />
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <MobileNavLink to="/">{t('home')}</MobileNavLink>
            <MobileNavLink to="/about">{t('about')}</MobileNavLink>
            <MobileNavLink to="/features">{t('features')}</MobileNavLink>
            <MobileNavLink to="/contact">{t('contact')}</MobileNavLink>
            <MobileNavLink to="/dashboard">{t('Dashboard')}</MobileNavLink>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-4">
            <AuthButton />
            <Link
             to="/register"
             className="ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              {t('register')}
            </Link>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <LanguageButton lang="en" currentLang={i18n.language} changeLanguage={changeLanguage} mobile />
              <LanguageButton lang="fr" currentLang={i18n.language} changeLanguage={changeLanguage} mobile />
              <LanguageButton lang="rw" currentLang={i18n.language} changeLanguage={changeLanguage} mobile />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

const NavLink = ({ to, children }) => (
  <Link
    to={to}
    className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ to, children }) => (
  <Link
    to={to}
    className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
  >
    {children}
  </Link>
);

const LanguageButton = ({ lang, currentLang, changeLanguage, mobile }) => (
  <button
    onClick={() => changeLanguage(lang)}
    className={`${
      mobile
        ? 'block w-full text-left px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700'
        : 'px-3 py-1 text-sm font-medium rounded-md'
    } ${
      currentLang === lang
        ? 'bg-blue-100 text-blue-800'
        : 'text-gray-500 hover:bg-gray-100 hover:text-gray-900'
    }`}
  >
    {lang.toUpperCase()}
  </button>
);

export default Navbar;