import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import About from './components/About';
import Features from './components/Features';
import Login from './components/Login';
import Register from './components/Register';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import UserProfiles from './components/UserProfiles';
import UserRolesPermissions from './components/UserRolesPermissions';
import CooperativeProfile from './components/CooperativeProfile';
import MemberDirectory from './components/MemberDirectory';
import CashBook from './components/CashBook';
import DebtorsBook from './components/DebtorsBook';
import CreditorsBook from './components/CreditorsBook';
import MembersShares from './components/MembersShares';
import PurchaseBook from './components/PurchaseBook';
import SalesBook from './components/SalesBook';
import StockDocuments from './components/StockDocuments';
import IntangibleDocuments from './components/IntangibleDocuments';
import InvoiceGeneration from './components/InvoiceGeneration';
import DeliveryNote from './components/DeliveryNote';
import BalanceSheet from './components/BalanceSheet';
import IncomeStatement from './components/IncomeStatement';
import InventoryTracking from './components/InventoryTracking';
import InventoryTransactions from './components/InventoryTransactions';
import StockReports from './components/StockReports';
import StockAlerts from './components/StockAlerts';
import SupplierManagement from './components/SupplierManagement';
import { CooperativeProvider } from './contexts/CooperativeContext';




function App() {
  return (
  <CooperativeProvider> 
    <Router>
      <Navbar />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user-management/profiles" element={<UserProfiles />} />
        <Route path="/user-management/roles" element={<UserRolesPermissions />} />
        <Route path="cooperative-management/profile" element={<CooperativeProfile />} />
        <Route path="cooperative-management/members" element={<MemberDirectory />} />
        <Route path="books/cash-book" element={<CashBook />} />
        <Route path="books/debtors-book" element={<DebtorsBook />} />
        <Route path="books/creditors-book" element={<CreditorsBook />} />
        <Route path="books/members-shares" element={<MembersShares />} />
        <Route path="books/purchase-book" element={<PurchaseBook />} />
        <Route path="books/sales-book" element={<SalesBook />} />
        <Route path="books/stockDocuments" element={<StockDocuments />} />
        <Route path="books/intangible-documents" element={<IntangibleDocuments />} />
        <Route path="books/invoice-generation" element={<InvoiceGeneration />} />
        <Route path="books/delivery-note" element={<DeliveryNote />} />
        <Route path="books/balance-sheet" element={<BalanceSheet />} />
        <Route path="books/income-statement" element={<IncomeStatement />} />
        <Route path="inventory-management/tracking" element={<InventoryTracking />} />
        <Route path="inventory-management/transactions" element={<InventoryTransactions />} />
        <Route path="inventory-management/reports" element={<StockReports />} />
        <Route path="inventory-management/alerts" element={<StockAlerts />} />
        <Route path="inventory-management/suppliers" element={<SupplierManagement />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  </CooperativeProvider>
  );
}

export default App;