import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';

Chart.register(...registerables);

const API_BASE_URL = 'https://kooplify.nw.r.appspot.com/api';

const Overview = () => {
  const { t } = useTranslation();
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/overview`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        setOverviewData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching overview data:', err);
        
        if (err.response) {
          if (err.response.status === 401) {
            setError(t('error_unauthorized'));
          } else if (err.response.status === 403) {
            setError(t('error_forbidden'));
          } else if (err.response.status === 500) {
            setError(t('error_server'));
          } else {
            setError(`${t('error_response')}: ${err.response.status}`);
          }
        } else if (err.request) {
          setError(t('error_no_response'));
        } else {
          setError(`${t('error_request')}: ${err.message}`);
        }
        
        setLoading(false);
      }
    };

    fetchOverviewData();
  }, [t]);

  if (loading) return <div className="flex justify-center items-center h-screen">{t('loading')}</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;
  if (!overviewData) return <div className="text-gray-500 text-center p-4">{t('no_data_available')}</div>;

  const { sales, cash, debtors, members } = overviewData;

  const lineChartData = {
    labels: sales.monthlySales.map(item => t(`month_${item.month}`)),
    datasets: [{
      label: t('sales'),
      data: sales.monthlySales.map(item => item.amount),
      fill: false,
      borderColor: 'rgb(59, 130, 246)',
      tension: 0.1,
    }],
  };

  const pieChartData = {
    labels: [t('members_active'), t('members_inactive')],
    datasets: [{
      data: [members.activeCount, members.inactiveCount],
      backgroundColor: ['rgb(59, 130, 246)', 'rgb(209, 213, 219)'],
    }],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center">{t('overview')}</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <SummaryCard title={t('total_sales')} value={sales.totalSales} />
        <SummaryCard title={t('cash_balance')} value={cash.balance} />
        <SummaryCard title={t('total_debtors')} value={debtors.totalDebt} />
        <SummaryCard title={t('total_members')} value={members.totalMembers} />
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('monthly_sales')}</h3>
          <div className="h-64">
            <Line data={lineChartData} options={chartOptions} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('member_status')}</h3>
          <div className="h-64">
            <Pie data={pieChartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryCard = ({ title, value }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition duration-300 ease-in-out transform hover:scale-105">
    <h3 className="text-lg font-semibold mb-2 text-gray-700">{title}</h3>
    <p className="text-3xl font-bold text-blue-600">{value}</p>
  </div>
);

export default Overview;