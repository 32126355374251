import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://kooplify.nw.r.appspot.com/api',
});

const AuthButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLoggedIn = !!localStorage.getItem('token'); // Check if token exists

  const handleLogout = async () => {
    try {
      // Call logout endpoint if you have one
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('cooperativeId');
      
      // Clear the Authorization header
      delete api.defaults.headers.common['Authorization'];
      
      // Redirect to login page
      navigate('/login');
    }
  };

  return (
    <div className="flex items-center ml-6">
      {isLoggedIn ? (
        <button
          onClick={handleLogout}
          className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          {t('logout')}
        </button>
      ) : (
        <NavLink
          to="/login"
          className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          {t('login')}
        </NavLink>
      )}
    </div>
  );
}; 

export default AuthButton;