import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const PurchaseBook = () => {
  const { cooperativeId } = useCooperative();
  const [editingId, setEditingId, cancelEdit] = useState(null);
  console.log('Current cooperativeId:', cooperativeId);

  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    itemPurchased: '',
    quantity: '',
    price: '',
    supplierName: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchEntries = useCallback(async () => {
    console.log('fetchEntries called, cooperativeId:', cooperativeId);
    if (!cooperativeId) {
      console.log('No cooperativeId, setting error');
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      console.log('Making API request to fetch entries');
      const response = await axios.get(`${API_BASE_URL}/api/purchases`, {
        params: { cooperativeId }
      });
      console.log('API response received:', response.data);
      setEntries(response.data);
      console.log('Entries state updated');
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    console.log('useEffect triggered, cooperativeId:', cooperativeId);
    if (cooperativeId) {
      console.log('Calling fetchEntries from useEffect');
      fetchEntries();
    } else {
      console.log('No cooperativeId available, skipping fetchEntries');
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/purchases/${editingEntry._id}`
        : `${API_BASE_URL}/api/purchases`;

      // Include cooperativeId in the data
      const requestData = { 
        ...data, 
        cooperativeId,
        quantity: Number(data.quantity),
        price: Number(data.price)
      };

      await axios[method](url, requestData);

      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchEntries();
      setNewEntry({ date: '', itemPurchased: '', quantity: '', price: '', supplierName: '' });
      setEditingEntry(null);
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry({
      ...entry,
      date: new Date(entry.date).toISOString().split('T')[0]
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/purchases/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{t('purchase_book')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <input
          type="date"
          name="date"
          value={newEntry.date}
          onChange={handleInputChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="itemPurchased"
          value={newEntry.itemPurchased}
          onChange={handleInputChange}
          placeholder={t('item_service_purchased')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="quantity"
          value={newEntry.quantity}
          onChange={handleInputChange}
          placeholder={t('quantity')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          name="price"
          value={newEntry.price}
          onChange={handleInputChange}
          placeholder={t('price')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="supplierName"
          value={newEntry.supplierName}
          onChange={handleInputChange}
          placeholder={t('supplier_name')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <div className="flex space-x-2">
          <button 
            type="submit"
            className="flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {editingId ? t('update_entry') : t('add_entry')}
          </button>
          {editingId && (
            <button 
              type="button" 
              onClick={cancelEdit}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              {t('cancel')}
            </button>
          )}
        </div>
      </form>
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="p-3 text-left">{t('date')}</th>
              <th className="p-3 text-left">{t('item_service_purchased')}</th>
              <th className="p-3 text-left">{t('quantity')}</th>
              <th className="p-3 text-left">{t('price')}</th>
              <th className="p-3 text-left">{t('total_amount')}</th>
              <th className="p-3 text-left">{t('supplier_name')}</th>
              <th className="p-3 text-left">{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {entries.map((entry) => (
              <tr key={entry._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="p-3">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="p-3">{entry.itemPurchased}</td>
                <td className="p-3">{entry.quantity}</td>
                <td className="p-3">{entry.price.toFixed(2)}</td>
                <td className="p-3">{entry.totalAmount.toFixed(2)}</td>
                <td className="p-3">{entry.supplierName}</td>
                <td className="p-3">
                  <button 
                    onClick={() => handleEdit(entry)}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2 transition duration-300"
                  >
                    {t('edit')}
                  </button>
                  <button 
                    onClick={() => handleDelete(entry._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded transition duration-300"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseBook;