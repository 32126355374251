import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const RegistrationForm = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const password = watch("password");
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmitError(null);
    try {
      const response = await axios.post('https://kooplify.nw.r.appspot.com/api/users/register', data);
      console.log(response.data);
      // Handle successful registration 
    } catch (error) {
      console.error('Registration error:', error);
      setSubmitError(error.response?.data?.message || 'An error occurred during registration');
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const inputClass = "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500";
  const labelClass = "block text-sm font-medium text-gray-700 mb-1";
  const errorClass = "text-red-500 text-xs mt-1";
  const buttonClass = "px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {step === 1 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{t('personal_information')}</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="fullName" className={labelClass}>{t('full_name')}</label>
                <input id="fullName" {...register("fullName", { required: true })} className={inputClass} placeholder={t('full_name_placeholder')} />
                {errors.fullName && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="email" className={labelClass}>{t('email_address')}</label>
                <input id="email" type="email" {...register("email", { required: true })} className={inputClass} placeholder={t('email_placeholder')} />
                {errors.email && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="phone" className={labelClass}>{t('phone_number')}</label>
                <input id="phone" {...register("phone", { required: true })} className={inputClass} placeholder={t('phone_placeholder')} />
                {errors.phone && <span className={errorClass}>{t('field_required')}</span>}
              </div>
            </div>
            
            <button onClick={nextStep} className={`${buttonClass} mt-4`}>{t('next')}</button>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{t('account_information')}</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="username" className={labelClass}>{t('username')}</label>
                <input id="username" {...register("username", { required: true })} className={inputClass} placeholder={t('username_placeholder')} />
                {errors.username && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="password" className={labelClass}>{t('password')}</label>
                <input id="password" type="password" {...register("password", { required: true, minLength: 8 })} className={inputClass} placeholder={t('password_placeholder')} />
                {errors.password && <span className={errorClass}>{t('password_length_error')}</span>}
              </div>
              
              <div>
                <label htmlFor="confirmPassword" className={labelClass}>{t('confirm_password')}</label>
                <input id="confirmPassword" type="password" {...register("confirmPassword", {
                  validate: value => value === password || t('password_mismatch')
                })} className={inputClass} placeholder={t('confirm_password_placeholder')} />
                {errors.confirmPassword && <span className={errorClass}>{errors.confirmPassword.message}</span>}
              </div>
            </div>
            
            <div className="flex justify-between mt-4">
              <button onClick={prevStep} className={buttonClass}>{t('previous')}</button>
              <button onClick={nextStep} className={buttonClass}>{t('next')}</button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{t('cooperative_details')}</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="cooperativeName" className={labelClass}>{t('cooperative_name')}</label>
                <input id="cooperativeName" {...register("cooperativeName")} className={inputClass} placeholder={t('cooperative_name_placeholder')} />
              </div>
              
              <div>
                <label htmlFor="cooperativeId" className={labelClass}>{t('cooperative_id')}</label>
                <input id="cooperativeId" {...register("cooperativeId")} className={inputClass} placeholder={t('cooperative_id_placeholder')} />
              </div>

              <div>
                <label htmlFor="cooperativeActivity" className={labelClass}>{t('cooperativeActivity')}</label>
                <input id="cooperativeActivity" {...register("cooperativeActivity")} className={inputClass} placeholder={t('cooperativeActivity_placeholder')} />
              </div>
              
              <div>
                <label htmlFor="role" className={labelClass}>{t('role')}</label>
                <select id="role" {...register("role")} className={inputClass}>
                  <option value="">{t('select_role')}</option>
                  <option value="member">{t('member')}</option>
                  <option value="admin">{t('admin')}</option>
                  <option value="treasurer">{t('treasurer')}</option>
                </select>
              </div>
              
              <div>
                <label htmlFor="membershipNumber" className={labelClass}>{t('membership_number')}</label>
                <input id="membershipNumber" {...register("membershipNumber")} className={inputClass} placeholder={t('membership_number_placeholder')} />
              </div>
            </div>
            
            <div className="flex justify-between mt-4">
              <button onClick={prevStep} className={buttonClass}>{t('previous')}</button>
              <button onClick={nextStep} className={buttonClass}>{t('next')}</button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{t('contact_information')}</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="country" className={labelClass}>{t('country')}</label>
                <input id="country" {...register("country", { required: true })} className={inputClass} placeholder={t('Country_placeholder')} />
                {errors.country && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="province" className={labelClass}>{t('province')}</label>
                <input id="province" {...register("province", { required: true })} className={inputClass} placeholder={t('Province_placeholder')} />
                {errors.province && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="district" className={labelClass}>{t('district')}</label>
                <input id="district" {...register("district", { required: true })} className={inputClass} placeholder={t('district_placeholder')} />
                {errors.district && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="sector" className={labelClass}>{t('sector')}</label>
                <input id="sector" {...register("sector", { required: true })} className={inputClass} placeholder={t('Sector_placeholder')} />
                {errors.sector && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="cell" className={labelClass}>{t('cell')}</label>
                <input id="cell" {...register("cell", { required: true })} className={inputClass} placeholder={t('Cell_placeholder')} />
                {errors.cell && <span className={errorClass}>{t('field_required')}</span>}
              </div>

              <div>
                <label htmlFor="village" className={labelClass}>{t('village')}</label>
                <input id="village" {...register("village", { required: true })} className={inputClass} placeholder={t('Village_placeholder')} />
                {errors.village && <span className={errorClass}>{t('field_required')}</span>}
              </div>
            </div>
            
            <div className="flex justify-between mt-4">
              <button onClick={prevStep} className={buttonClass}>{t('previous')}</button>
              <button onClick={nextStep} className={buttonClass}>{t('next')}</button>
            </div>
          </div>
        )}

        {step === 5 && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{t('security_features')}</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="securityQuestion1" className={labelClass}>{t('security_question_1')}</label>
                <input id="securityQuestion1" {...register("securityQuestion1", { required: true })} className={inputClass} placeholder={t('security_question_1_placeholder')} />
                {errors.securityQuestion1 && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="securityAnswer1" className={labelClass}>{t('security_answer_1')}</label>
                <input id="securityAnswer1" {...register("securityAnswer1", { required: true })} className={inputClass} placeholder={t('security_answer_1_placeholder')} />
                {errors.securityAnswer1 && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="securityQuestion2" className={labelClass}>{t('security_question_2')}</label>
                <input id="securityQuestion2" {...register("securityQuestion2", { required: true })} className={inputClass} placeholder={t('security_question_2_placeholder')} />
                {errors.securityQuestion2 && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <label htmlFor="securityAnswer2" className={labelClass}>{t('security_answer_2')}</label>
                <input id="securityAnswer2" {...register("securityAnswer2", { required: true })} className={inputClass} placeholder={t('security_answer_2_placeholder')} />
                {errors.securityAnswer2 && <span className={errorClass}>{t('field_required')}</span>}
              </div>
              
              <div>
                <ReCAPTCHA
                  sitekey="6LduYhwqAAAAABAOHofOWENlfTJ6b8_9zhMa25L_"
                  onChange={() => {}}
                />
              </div>
              
              <div>
                <label className="flex items-center">
                  <input type="checkbox" {...register("twoFactor")} className="mr-2" />
                  <span className="text-sm">{t('enable_two_factor')}</span>
                </label>
              </div>
            </div>
            
            <div className="flex justify-between mt-4">
              <button onClick={prevStep} className={buttonClass}>{t('previous')}</button>
              <button onClick={nextStep} className={buttonClass}>{t('next')}</button>
            </div>
          </div>
        )}

   {step === 6 && (
     <div>
      <h2 className="text-2xl font-bold mb-4">{t('terms_and_conditions')}</h2>
      <div className="space-y-4">
        <div>
          <label className="flex items-center">
            <input type="checkbox" {...register("agreeTerms", { required: true })} className="mr-2" />
            <span className="text-sm">{t('agree_terms')}</span>
          </label>
          {errors.agreeTerms && <span className={errorClass}>{t('must_agree_terms')}</span>}
        </div>
        
        <div>
          <label htmlFor="heardAbout" className={labelClass}>{t('heard_about_us')}</label>
          <select id="heardAbout" {...register("heardAbout")} className={inputClass}>
            <option value="">{t('select_option')}</option>
            <option value="search">{t('search_engine')}</option>
            <option value="friend">{t('friend')}</option>
            <option value="ad">{t('advertisement')}</option>
          </select>
        </div>
        
        <div>
          <label htmlFor="referralCode" className={labelClass}>{t('referral_code')}</label>
          <input id="referralCode" {...register("referralCode")} className={inputClass} placeholder={t('referral_code_placeholder')} />
        </div>
      </div>
      
      {submitError && <div className="text-red-500 mt-4">{submitError}</div>}
      
      <div className="flex justify-between mt-4">
        <button onClick={prevStep} className={buttonClass} disabled={isSubmitting}>{t('previous')}</button>
        <button type="submit" className={buttonClass} disabled={isSubmitting}>
          {isSubmitting ? t('submitting') : t('register')}
        </button>
      </div>
    </div>
    )}

   </form>
  </div>
  );
};

export default RegistrationForm;