import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './InventoryTracking.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://kooplify.nw.r.appspot.com';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const InventoryTracking = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [newItem, setNewItem] = useState({ name: '', code: '', category: '', quantity: 0, minStock: 0, reorderLevel: 0 });
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    if (cooperativeId) {
      fetchInventory();
    }
  }, [cooperativeId]);

  const fetchInventory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/inventory`, {
        params: { cooperativeId }
      });
      setInventory(response.data);
      setLoading(false);
    } catch (err) {
      setError(t('failed_fetch_inventory'));
      setLoading(false);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/api/inventory`, { ...newItem, cooperativeId });
      fetchInventory();
      setNewItem({ name: '', code: '', category: '', quantity: 0, minStock: 0, reorderLevel: 0 });
    } catch (err) {
      setError(t('failed_add_item'));
    }
  };

  const handleUpdateItem = async () => {
    try {
      await axios.put(`${API_BASE_URL}/api/inventory/${editingItem._id}`, { ...editingItem, cooperativeId });
      fetchInventory();
      setEditingItem(null);
    } catch (err) {
      setError(t('failed_update_item'));
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/inventory/${id}`, {
        params: { cooperativeId }
      });
      fetchInventory();
    } catch (err) {
      setError(t('failed_delete_item'));
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredInventory = inventory.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedInventory = React.useMemo(() => {
    let sortableItems = [...filteredInventory];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredInventory, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  if (!cooperativeId) return <div className={styles.error}>{t('no_cooperative_id')}</div>;
  if (loading) return <div className={styles.loading}>{t('loading')}</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h2>{t('inventory_tracking')}</h2>
      
      <form onSubmit={handleAddItem} className={styles.addForm}>
        <input
          type="text"
          placeholder={t('item_name')}
          value={newItem.name}
          onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder={t('item_code')}
          value={newItem.code}
          onChange={(e) => setNewItem({ ...newItem, code: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder={t('category')}
          value={newItem.category}
          onChange={(e) => setNewItem({ ...newItem, category: e.target.value })}
          required
        />
        <input
          type="number"
          placeholder={t('quantity')}
          value={newItem.quantity}
          onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
          required
        />
        <input
          type="number"
          placeholder={t('minimum_stock')}
          value={newItem.minStock}
          onChange={(e) => setNewItem({ ...newItem, minStock: parseInt(e.target.value) })}
          required
        />
        <input
          type="number"
          placeholder={t('reorder_level')}
          value={newItem.reorderLevel}
          onChange={(e) => setNewItem({ ...newItem, reorderLevel: parseInt(e.target.value) })}
          required
        />
        <button type="submit">{t('add_item')}</button>
      </form>

      <input
        type="text"
        placeholder={t('search_inventory')}
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchInput}
      />

      <table className={styles.inventoryTable}>
        <thead>
          <tr>
            <th onClick={() => requestSort('name')}>{t('item_name')}</th>
            <th onClick={() => requestSort('code')}>{t('item_code')}</th>
            <th onClick={() => requestSort('category')}>{t('category')}</th>
            <th onClick={() => requestSort('quantity')}>{t('quantity_in_stock')}</th>
            <th onClick={() => requestSort('minStock')}>{t('minimum_stock_level')}</th>
            <th onClick={() => requestSort('reorderLevel')}>{t('reorder_level')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedInventory.map((item) => (
            <tr key={item._id}>
              {editingItem && editingItem._id === item._id ? (
                <>
                  <td><input value={editingItem.name} onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })} /></td>
                  <td><input value={editingItem.code} onChange={(e) => setEditingItem({ ...editingItem, code: e.target.value })} /></td>
                  <td><input value={editingItem.category} onChange={(e) => setEditingItem({ ...editingItem, category: e.target.value })} /></td>
                  <td><input type="number" value={editingItem.quantity} onChange={(e) => setEditingItem({ ...editingItem, quantity: parseInt(e.target.value) })} /></td>
                  <td><input type="number" value={editingItem.minStock} onChange={(e) => setEditingItem({ ...editingItem, minStock: parseInt(e.target.value) })} /></td>
                  <td><input type="number" value={editingItem.reorderLevel} onChange={(e) => setEditingItem({ ...editingItem, reorderLevel: parseInt(e.target.value) })} /></td>
                  <td>
                    <button onClick={handleUpdateItem} className={styles.saveBtn}>{t('save')}</button>
                    <button onClick={() => setEditingItem(null)} className={styles.cancelBtn}>{t('cancel')}</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                  <td>{item.category}</td>
                  <td>{item.quantity}</td>
                  <td>{item.minStock}</td>
                  <td>{item.reorderLevel}</td>
                  <td>
                    <button onClick={() => setEditingItem(item)} className={styles.editBtn}>{t('edit')}</button>
                    <button onClick={() => handleDeleteItem(item._id)} className={styles.deleteBtn}>{t('delete')}</button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTracking;