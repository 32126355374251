// UserRolesPermissions.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserRolesPermissions.module.css';

const UserRolesPermissions = () => {
  const { t } = useTranslation();

  const roles = [
    { name: 'Admin', permissions: ['Full access to all features and settings'] },
    { name: 'Member', permissions: ['Access to personal profile', 'View cooperative information', 'Engage in member activities'] },
    { name: 'Accountant', permissions: ['Access to financial records', 'Bookkeeping', 'Reporting features'] },
  ];

  return (
    <div className={styles.container}>
      <h2>{t('roles_and_permissions')}</h2>
      {roles.map((role) => (
        <div key={role.name} className={styles.roleCard}>
          <h3>{role.name}</h3>
          <ul>
            {role.permissions.map((permission, index) => (
              <li key={index}>{permission}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default UserRolesPermissions;