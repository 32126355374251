import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserProfiles.module.css';

const UserProfiles = () => {
  console.log("UserProfiles component is rendering");
  const { t } = useTranslation();
  console.log("Translation function loaded:", !!t);

  // This would come from an API or database
  const users = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      phone: '123-456-7890',
      address: '123 Main St, City, Country',
      membershipId: 'M001',
      joiningDate: '2022-01-01',
      role: 'Member',
    },
    
  ];

  console.log("Users data:", users);

  return (
    <div className={styles.container} style={{border: '1px solid black', padding: '20px'}}>
      <h2 style={{color: 'blue'}}>{t('user_profiles') || 'User Profiles'}</h2>
      {users.length > 0 ? (
        users.map((user) => (
          <div key={user.id} className={styles.userCard} style={{border: '1px solid gray', margin: '10px', padding: '10px'}}>
            <h3>{user.name}</h3>
            <p>{t('email') || 'Email'}: {user.email}</p>
            <p>{t('phone') || 'Phone'}: {user.phone}</p>
            <p>{t('address') || 'Address'}: {user.address}</p>
            <p>{t('membership_id') || 'Membership ID'}: {user.membershipId}</p>
            <p>{t('joining_date') || 'Joining Date'}: {user.joiningDate}</p>
            <p>{t('role') || 'Role'}: {user.role}</p>
            <button>{t('view_activity_log') || 'View Activity Log'}</button>
          </div>
        ))
      ) : (
        <p>No users found.</p>
      )}
    </div>
  );
};

export default UserProfiles;