import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { 
  FaUsers, FaBuilding, FaUsersCog, FaUserEdit, FaBook, FaBoxes, 
  FaGraduationCap, FaComments, FaClipboardCheck, FaAddressCard, FaUserFriends,
  FaMoneyBillWave, FaUserTag, FaHandHoldingUsd, FaShoppingCart, FaStore, FaGem,
  FaFileInvoiceDollar, FaTruck, FaBalanceScale, FaChartLine, FaExchangeAlt, 
  FaClipboardList, FaChartBar, FaBell, FaChevronDown, FaChevronUp, FaBars, FaTimes
} from 'react-icons/fa';

const navItems = [
  {
    to: '/user-management',
    icon: FaUsers,
    label: 'user_management',
    subItems: [
      { to: '/user-management/roles', icon: FaUsersCog, label: 'roles_and_permissions' },
      { to: '/user-management/profiles', icon: FaUserEdit, label: 'user_profiles' },
    ]
  },
  {
    to: '/cooperative-management',
    icon: FaBuilding,
    label: 'cooperative_management',
    subItems: [
      { to: '/cooperative-management/profile', icon: FaAddressCard, label: 'cooperative_profile' },
      { to: '/cooperative-management/members', icon: FaUserFriends, label: 'member_directory' },
    ]
  },
  {
    to: '/books',
    icon: FaBook,
    label: 'books',
    subItems: [
      { to: '/books/cash-book', icon: FaMoneyBillWave, label: 'cash_book' },
      { to: '/books/debtors-book', icon: FaUserTag, label: 'debtors_book' },
      { to: '/books/creditors-book', icon: FaHandHoldingUsd, label: 'creditors_book' },
      { to: '/books/members-shares', icon: FaUsers, label: 'members_shares' },
      { to: '/books/purchase-book', icon: FaShoppingCart, label: 'purchase_book' },
      { to: '/books/sales-book', icon: FaStore, label: 'sales_book' },
      { to: '/books/stockDocuments', icon: FaBoxes, label: 'stock_documents' },
      { to: '/books/intangible-documents', icon: FaGem, label: 'intangible_documents' },
      { to: '/books/invoice-generation', icon: FaFileInvoiceDollar, label: 'invoice_generation' },
      { to: '/books/delivery-note', icon: FaTruck, label: 'delivery_note' },
      { to: '/books/balance-sheet', icon: FaBalanceScale, label: 'balance_sheet' },
      { to: '/books/income-statement', icon: FaChartLine, label: 'income_statement' },
    ]
  },
  {
    to: '/inventory-management',
    icon: FaBoxes,
    label: 'inventory_management',
    subItems: [
      { to: '/inventory-management/tracking', icon: FaClipboardList, label: 'inventory_tracking' },
      { to: '/inventory-management/transactions', icon: FaExchangeAlt, label: 'inventory_transactions' },
      { to: '/inventory-management/reports', icon: FaChartBar, label: 'stock_reports' },
      { to: '/inventory-management/alerts', icon: FaBell, label: 'stock_alerts' },
      { to: '/inventory-management/suppliers', icon: FaTruck, label: 'supplier_management' },
    ]
  },
  { to: '/training', icon: FaGraduationCap, label: 'training' },
  { to: '/communication', icon: FaComments, label: 'communication' },
  { to: '/compliance', icon: FaClipboardCheck, label: 'compliance' },
];

const NavMenu = ({ isMobile = false, onItemClick = () => {} }) => {
  const { t } = useTranslation();
  const [openMenus, setOpenMenus] = useState({});

  const toggleMenu = (index) => {
    setOpenMenus(prev => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <ul className={`space-y-2 ${isMobile ? 'px-4' : ''}`}>
      {navItems.map((item, index) => (
        <li key={item.to} className="mb-1">
          <button
            onClick={() => {
              toggleMenu(index);
              if (isMobile && !item.subItems) onItemClick();
            }}
            className="w-full flex items-center justify-between p-2 rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            <div className="flex items-center">
              <item.icon className="w-5 h-5 mr-3" />
              <span>{t(item.label)}</span>
            </div>
            {item.subItems && (
              openMenus[index] ? <FaChevronUp className="w-4 h-4" /> : <FaChevronDown className="w-4 h-4" />
            )}
          </button>
          {item.subItems && openMenus[index] && (
            <ul className="ml-6 mt-2 space-y-1">
              {item.subItems.map((subItem) => (
                <li key={subItem.to}>
                  <Link
                    to={subItem.to}
                    className="flex items-center p-2 text-sm rounded-md hover:bg-gray-700 transition-colors duration-200"
                    onClick={isMobile ? onItemClick : undefined}
                  >
                    <subItem.icon className="w-4 h-4 mr-3" />
                    <span>{t(subItem.label)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const Navigation = () => {
  return (
    <nav className="bg-gray-800 text-white w-64 min-h-screen p-4 hidden md:block">
      <NavMenu />
    </nav>
  );
};

const MobileNavigation = ({ isOpen, onClose }) => {
  return (
    <div className={`fixed inset-0 bg-gray-800 text-white z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
      <div className="flex justify-between items-center p-4 border-b border-gray-700">
        <h2 className="text-xl font-bold">Menu</h2>
        <button onClick={onClose} className="text-white">
          <FaTimes className="w-6 h-6" />
        </button>
      </div>
      <div className="overflow-y-auto h-full pb-20">
        <NavMenu isMobile={true} onItemClick={onClose} />
      </div>
    </div>
  );
};

const ResponsiveNavigation = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <>
      <button
        className="md:hidden fixed top-4 left-4 z-20 p-2 bg-gray-800 text-white rounded-md"
        onClick={() => setIsMobileNavOpen(true)}
      >
        <FaBars className="w-6 h-6" />
      </button>
      <Navigation />
      <MobileNavigation isOpen={isMobileNavOpen} onClose={() => setIsMobileNavOpen(false)} />
    </>
  );
};

export default ResponsiveNavigation;