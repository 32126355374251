import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Bar, Line } from 'react-chartjs-2';
import styles from './BookComponent.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const StockDocuments = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    itemDescription: '',
    quantityReceived: '',
    quantityIssued: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchStocks = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/stocks`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_fetching_stocks'));
      console.error('Error fetching stocks:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchStocks();
    }
  }, [cooperativeId, fetchStocks]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/stocks/${editingEntry._id}`
        : `${API_BASE_URL}/api/stocks`;

      const requestData = { ...data, cooperativeId };

      await axios[method](url, requestData);

      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchStocks();
      setNewEntry({ date: '', itemDescription: '', quantityReceived: '', quantityIssued: '' });
      setEditingEntry(null);
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry(entry);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/stocks/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchStocks();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const checkInventoryLevels = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/stocks/inventory-levels?cooperativeId=${cooperativeId}`);
      setInventoryData(response.data);
    } catch (error) {
      setError(t('error_checking_inventory_levels'));
      console.error('Error checking inventory levels:', error);
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };
  return (
    <div className={styles.container}>
      <h2>{t('stock_documents')}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="date"
          name="date"
          value={newEntry.date}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="itemDescription"
          value={newEntry.itemDescription}
          onChange={handleInputChange}
          placeholder={t('item_description')}
          required
        />
        <input
          type="number"
          name="quantityReceived"
          value={newEntry.quantityReceived}
          onChange={handleInputChange}
          placeholder={t('quantity_received')}
          required
        />
        <input
          type="number"
          name="quantityIssued"
          value={newEntry.quantityIssued}
          onChange={handleInputChange}
          placeholder={t('quantity_issued')}
          required
        />
        <button type="submit">{t('add_entry')}</button>
      </form>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('item_description')}</th>
            <th>{t('quantity_received')}</th>
            <th>{t('quantity_issued')}</th>
            <th>{t('balance')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr key={entry._id}>
              <td>{new Date(entry.date).toLocaleDateString()}</td>
              <td>{entry.itemDescription}</td>
              <td>{entry.quantityReceived}</td>
              <td>{entry.quantityIssued}</td>
              <td>{entry.balance}</td>
              <td>
                <button onClick={() => handleEdit(entry)}>{t('edit')}</button>
                <button onClick={() => handleDelete(entry._id)}>{t('delete')}</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={checkInventoryLevels} className={styles.inventoryButton}>
        {t('inventory_levels')}
      </button>
      {inventoryData && (
        <div className={styles.analyticsContainer}>
          <div className={styles.chart}>
            <h3>{t('current_inventory_levels')}</h3>
            <Bar
              data={{
                labels: inventoryData.inventoryLevels.map(item => item._id),
                datasets: [
                  {
                    label: t('current_stock'),
                    data: inventoryData.inventoryLevels.map(item => item.currentStock),
                    backgroundColor: inventoryData.inventoryLevels.map(item => {
                      switch(item.stockStatus) {
                        case 'Low': return 'rgba(255, 99, 132, 0.6)';
                        case 'Medium': return 'rgba(255, 206, 86, 0.6)';
                        case 'High': return 'rgba(75, 192, 192, 0.6)';
                        default: return 'rgba(75, 192, 192, 0.6)';
                      }
                    }),
                  },
                ],
              }}
            />
          </div>
          <div className={styles.chart}>
            <h3>{t('stock_over_time')}</h3>
            <Line
              data={{
                labels: inventoryData.stockOverTime.map(item => item._id),
                datasets: [
                  {
                    label: t('total_received'),
                    data: inventoryData.stockOverTime.map(item => item.totalReceived),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false,
                  },
                  {
                    label: t('total_issued'),
                    data: inventoryData.stockOverTime.map(item => item.totalIssued),
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false,
                  },
                  {
                    label: t('balance'),
                    data: inventoryData.stockOverTime.map(item => item.balance),
                    borderColor: 'rgba(255, 206, 86, 1)',
                    fill: false,
                  },
                ],
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StockDocuments;