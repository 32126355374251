// src/contexts/CooperativeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const CooperativeContext = createContext();

export const CooperativeProvider = ({ children }) => {
  const [cooperativeId, setCooperativeId] = useState(null);

  useEffect(() => {
    const storedCooperativeId = localStorage.getItem('cooperativeId');
    if (storedCooperativeId) setCooperativeId(storedCooperativeId);
  }, []);

  const updateCooperativeId = (id) => {
    setCooperativeId(id);
    localStorage.setItem('cooperativeId', id);
  };

  return (
    <CooperativeContext.Provider value={{ cooperativeId, updateCooperativeId }}>
      {children}
    </CooperativeContext.Provider>
  );
};

export const useCooperative = () => useContext(CooperativeContext);