import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarSign, Users, FileCheck, Clock, Smartphone, Upload, BarChart, Shield, Globe, Zap, PieChart, ThumbsUp, Star, Quote,  ChevronDown, ChevronUp, MapPin, Phone, Twitter, Facebook, Linkedin, Instagram, Mail, Send } from 'lucide-react';
import cooperativeImage from '../smilings.jpg';


const FeatureCard = ({ icon: Icon, titleKey, descriptionKey }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center hover:shadow-lg transition-shadow duration-300">
      <Icon className="w-12 h-12 text-blue-500 mb-4" />
      <h3 className="text-xl font-semibold mb-2">{t(titleKey)}</h3>
      <p className="text-gray-600">{t(descriptionKey)}</p>
    </div>
  );
};

const StepIcon = ({ icon: Icon, isActive }) => (
  <div className={`rounded-full p-3 ${isActive ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}>
    <Icon size={24} />
  </div>
);

const BenefitCard = ({ icon: Icon, titleKey, descriptionKey }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <Icon className="w-12 h-12 text-blue-500 mr-4 flex-shrink-0" />
      <div>
        <h3 className="text-xl font-semibold mb-2">{t(titleKey)}</h3>
        <p className="text-gray-600">{t(descriptionKey)}</p>
      </div>
    </div>
  );
};

const TestimonialCard = ({ quote, author, role, rating }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg relative">
      <Quote className="w-10 h-10 text-blue-500 absolute top-4 left-4 opacity-25" />
      <p className="text-lg mb-4 pt-8 px-4">{t(quote)}</p>
      <div className="flex items-center justify-between mt-4">
        <div>
          <p className="font-semibold">{t(author)}</p>
          <p className="text-sm text-gray-600">{t(role)}</p>
        </div>
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className={`w-5 h-5 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" />
          ))}
        </div>
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{t(question)}</h3>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-blue-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-blue-500" />
        )}
      </button>
      {isOpen && (
        <p className="mt-2 text-gray-600">{t(answer)}</p>
      )}
    </div>
  );
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 max-w-md mx-auto">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('name')}</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('email')}</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">{t('message')}</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Send className="w-4 h-4 mr-2" />
          {t('send_message')}
        </button>
      </div>
    </form>
  );
};

const ContactInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="space-y-4 text-center mt-8">
      <h3 className="text-lg font-medium text-gray-900">{t('contact_info')}</h3>
      <div className="flex items-center justify-center">
        <Mail className="w-5 h-5 text-gray-400 mr-2" />
        <span>info@kooplify.com</span>
      </div>
      <div className="flex items-center justify-center">
        <Phone className="w-5 h-5 text-gray-400 mr-2" />
        <span>+250785379885</span>
      </div>
      <div className="flex items-center justify-center">
        <MapPin className="w-5 h-5 text-gray-400 mr-2" />
        <span>kk 737 st, Kigali City, Rwanda</span>
      </div>
    </div>
  );
};

const HomePage = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const features = [
    {
      icon: DollarSign,
      titleKey: "homepage.features.financial.title",
      descriptionKey: "homepage.features.financial.description"
    },
    {
      icon: Users,
      titleKey: "homepage.features.collaboration.title",
      descriptionKey: "homepage.features.collaboration.description"
    },
    {
      icon: FileCheck,
      titleKey: "homepage.features.compliance.title",
      descriptionKey: "homepage.features.compliance.description"
    },
    {
      icon: Clock,
      titleKey: "homepage.features.administrative.title",
      descriptionKey: "homepage.features.administrative.description"
    }
  ];

  const steps = [
    { icon: Smartphone, title: 'step_1_title', description: 'step_1_description' },
    { icon: Upload, title: 'step_2_title', description: 'step_2_description' },
    { icon: BarChart, title: 'step_3_title', description: 'step_3_description' },
    { icon: Users, title: 'step_4_title', description: 'step_4_description' },
  ];


  React.useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 3000); // Change active step every 3 seconds
    return () => clearInterval(timer);
  }, []);

  const benefits = [
    {
      icon: DollarSign,
      titleKey: 'benefit_financial_title',
      descriptionKey: 'benefit_financial_description'
    },
    {
      icon: Users,
      titleKey: 'benefit_collaboration_title',
      descriptionKey: 'benefit_collaboration_description'
    },
    {
      icon: Shield,
      titleKey: 'benefit_security_title',
      descriptionKey: 'benefit_security_description'
    },
    {
      icon: Globe,
      titleKey: 'benefit_accessibility_title',
      descriptionKey: 'benefit_accessibility_description'
    },
    {
      icon: Zap,
      titleKey: 'benefit_efficiency_title',
      descriptionKey: 'benefit_efficiency_description'
    },
    {
      icon: PieChart,
      titleKey: 'benefit_reporting_title',
      descriptionKey: 'benefit_reporting_description'
    },
    {
      icon: ThumbsUp,
      titleKey: 'benefit_compliance_title',
      descriptionKey: 'benefit_compliance_description'
    },
    {
      icon: Clock,
      titleKey: 'benefit_timemanagement_title',
      descriptionKey: 'benefit_timemanagement_description'
    }
  ];

  const testimonials = [
    {
      quote: 'testimonial_1_quote',
      author: 'testimonial_1_author',
      role: 'testimonial_1_role',
      rating: 5
    },
    {
      quote: 'testimonial_2_quote',
      author: 'testimonial_2_author',
      role: 'testimonial_2_role',
      rating: 5
    },
    {
      quote: 'testimonial_3_quote',
      author: 'testimonial_3_author',
      role: 'testimonial_3_role',
      rating: 4
    }
  ];
 
  const faqs = [
    {
      question: 'faq_1_question',
      answer: 'faq_1_answer'
    },
    {
      question: 'faq_2_question',
      answer: 'faq_2_answer'
    },
    {
      question: 'faq_3_question',
      answer: 'faq_3_answer'
    },
    {
      question: 'faq_4_question',
      answer: 'faq_4_answer'
    },
    {
      question: 'faq_5_question',
      answer: 'faq_5_answer'
    }
  ];

  const socialLinks = [
    { icon: Twitter, href: 'https://twitter.com/kooplify', label: 'Twitter' },
    { icon: Facebook, href: 'https://facebook.com/kooplify', label: 'Facebook' },
    { icon: Linkedin, href: 'https://linkedin.com/company/kooplify', label: 'LinkedIn' },
    { icon: Instagram, href: 'https://instagram.com/kooplify', label: 'Instagram' },
  ];


  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow">
        <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-20">
  <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
    <div className="md:w-1/2 mb-10 md:mb-0">
      <h2 className="text-4xl md:text-5xl font-bold mb-4">{t('modern_cooperative_management')}</h2>
      <p className="text-xl mb-8">{t('kooplify_description')}</p>
      <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        {t('get_started')}
      </button>
    </div>
    <div className="md:w-1/2 md:pl-10">
      <img 
        src={cooperativeImage}
        alt="Cooperative Management" 
        className="rounded-lg shadow-xl transform hover:scale-105 transition duration-300"
      />
    </div>
  </div>
</section>

       <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-2 text-center">{t('homepage.features.heading')}</h2>
            <p className="text-xl text-gray-600 mb-8 text-center">{t('homepage.features.subheading')}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('how_it_works')}</h2>
            <p className="text-lg text-center max-w-2xl mx-auto mb-12">{t('how_it_works_description')}</p>
        
            <div className="flex flex-col md:flex-row justify-between items-center mb-12">
             {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center mb-8 md:mb-0">
              <StepIcon icon={step.icon} isActive={index === activeStep} />
              <div className="h-16 w-px bg-gray-300 my-2" />
              <h3 className="text-lg font-semibold text-center">{t(step.title)}</h3>
              <p className="text-sm text-gray-600 text-center mt-2 max-w-xs">{t(step.description)}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center">
          <svg width="600" height="300" viewBox="0 0 600 300">
            <rect x="50" y="50" width="500" height="200" rx="20" fill="#E5E7EB" />
            <circle cx="300" cy="150" r="80" fill="#3B82F6" />
            <text x="300" y="155" textAnchor="middle" fill="white" fontSize="24" fontWeight="bold">
              Kooplify
            </text>
            {steps.map((_, index) => (
              <g key={index} transform={`translate(${150 + index * 100}, 150) rotate(${index * 90})`}>
                <line x1="0" y1="0" x2="80" y2="0" stroke="#4B5563" strokeWidth="2" />
                <circle cx="80" cy="0" r="10" fill={index === activeStep ? '#3B82F6' : '#9CA3AF'} />
              </g>
            ))}
          </svg>
        </div>
      </div>
    </section>

    <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-4 text-center">{t('benefits')}</h2>
            <p className="text-xl text-gray-600 mb-8 text-center max-w-2xl mx-auto">{t('benefits_description')}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {benefits.map((benefit, index) => (
                <BenefitCard key={index} {...benefit} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-br from-blue-500 to-blue-600 text-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-2 text-center">{t('testimonials')}</h2>
            <p className="text-xl mb-8 text-center max-w-2xl mx-auto">{t('testimonials_description')}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} {...testimonial} />
              ))}
            </div>
          </div>
        </section>
        
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-2 text-center">{t('faq')}</h2>
            <p className="text-xl text-gray-600 mb-8 text-center max-w-2xl mx-auto">{t('faq_description')}</p>
            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('contact_us')}</h2>
            <p className="text-lg text-center max-w-2xl mx-auto mb-8">{t('contact_description')}</p>
            <ContactForm />
            <ContactInfo />
          </div>
        </section>
    </main>

    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4">{t('about_us')}</h3>
            <p className="text-gray-400">{t('footer_about_description')}</p>
          </div>
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4">{t('quick_links')}</h3>
            <ul className="space-y-2">
              <li><a href="#features" className="hover:text-blue-400 transition-colors">{t('features')}</a></li>
              <li><a href="#how-it-works" className="hover:text-blue-400 transition-colors">{t('how_it_works')}</a></li>
              <li><a href="#benefits" className="hover:text-blue-400 transition-colors">{t('benefits')}</a></li>
              <li><a href="#testimonials" className="hover:text-blue-400 transition-colors">{t('testimonials')}</a></li>
              <li><a href="#faq" className="hover:text-blue-400 transition-colors">{t('faq')}</a></li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4">{t('contact_info')}</h3>
            <div className="flex items-center justify-center md:justify-start mb-2">
              <MapPin className="mr-2" size={18} />
              <p>{t('company_address')}</p>
            </div>
            <div className="flex items-center justify-center md:justify-start">
              <Phone className="mr-2" size={18} />
              <p>{t('company_phone')}</p>
            </div>
          </div>
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4">{t('follow_us')}</h3>
            <div className="flex justify-center md:justify-start space-x-4">
              {socialLinks.map((link) => (
                <a
                  key={link.label}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-400 transition-colors"
                  aria-label={link.label}
                >
                  <link.icon size={24} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>&copy; {new Date().getFullYear()} Kooplify. {t('all_rights_reserved')}</p>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default HomePage;