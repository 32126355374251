import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './StockAlerts.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://kooplify.nw.r.appspot.com';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const StockAlerts = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (cooperativeId) {
      fetchAlerts();
    }
  }, [cooperativeId]);

  const fetchAlerts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/stock-alerts`, {
        params: { cooperativeId }
      });
      setAlerts(response.data);
      setLoading(false);
    } catch (err) {
      setError(t('failed_fetch_alerts'));
      setLoading(false);
    }
  };

  const handleDismiss = async (alertId) => {
    try {
      await axios.post(`${API_BASE_URL}/api/stock-alerts/${alertId}/dismiss`, { cooperativeId });
      setAlerts(alerts.filter(alert => alert.id !== alertId));
    } catch (err) {
      setError(t('failed_dismiss_alert'));
    }
  };

  if (!cooperativeId) return <div className={styles.error}>{t('no_cooperative_id')}</div>;
  if (loading) return <div className={styles.loading}>{t('loading')}</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h2>{t('stock_alerts')}</h2>
      <div className={styles.alertList}>
        {alerts.map((alert) => (
          <div key={alert.id} className={styles.alertItem}>
            <span className={styles.alertIcon}>⚠️</span>
            <span className={styles.alertText}>
              {t(alert.type, { item: alert.itemName, level: alert.level })}
            </span>
            <button onClick={() => handleDismiss(alert.id)} className={styles.dismissButton}>
              {t('dismiss')}
            </button>
          </div>
        ))}
        {alerts.length === 0 && (
          <p className={styles.noAlerts}>{t('no_alerts')}</p>
        )}
      </div>
    </div>
  );
};

export default StockAlerts;