// CooperativeProfile.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CooperativeProfile.module.css';

const CooperativeProfile = () => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({
    name: 'Sample Cooperative',
    registrationNumber: 'REG123456',
    address: '123 Coop Street, Kigali, Rwanda',
    contactInfo: 'contact@samplecoop.rw | +250 123 456 789',
    description: 'A cooperative dedicated to improving livelihoods through sustainable agriculture.',
    objectives: [
      'Increase member income through collective marketing',
      'Provide training on modern farming techniques',
      'Facilitate access to affordable inputs and credit'
    ]
  });

  return (
    <div className={styles.container}>
      <h2>{t('cooperative_profile')}</h2>
      <div className={styles.profileInfo}>
        <p><strong>{t('name')}:</strong> {profile.name}</p>
        <p><strong>{t('registration_number')}:</strong> {profile.registrationNumber}</p>
        <p><strong>{t('address_and_contact')}:</strong> {profile.address}</p>
        <p>{profile.contactInfo}</p>
        <p><strong>{t('description')}:</strong> {profile.description}</p>
        <div>
          <strong>{t('objectives')}:</strong>
          <ul>
            {profile.objectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CooperativeProfile;