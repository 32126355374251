import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Bar, Line } from 'react-chartjs-2';
import styles from './BookComponent.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const IntangibleDocuments = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [editingId, setEditingId] = useState(null);
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    description: '',
    value: '',
    amortization: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchIntangibles = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/intangibles`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_fetching_intangibles'));
      console.error('Error fetching intangibles:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchIntangibles();
    }
  }, [cooperativeId, fetchIntangibles]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/intangibles/${editingEntry._id}`
        : `${API_BASE_URL}/api/intangibles`;

      const requestData = { ...data, cooperativeId };

      await axios[method](url, requestData);

      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchIntangibles();
      setNewEntry({ date: '', description: '', value: '', amortization: '' });
      setEditingEntry(null);
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry(entry);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/intangibles/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchIntangibles();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const getAnalytics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/intangibles/analytics?cooperativeId=${cooperativeId}`);
      setAnalytics(response.data);
    } catch (error) {
      setError(t('error_fetching_analytics'));
      console.error('Error fetching analytics:', error);
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('intangible_documents')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-4">
            <input
              type="date"
              name="date"
              value={newEntry.date}
              onChange={handleInputChange}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-4">
            <input
              type="text"
              name="description"
              value={newEntry.description}
              onChange={handleInputChange}
              placeholder={t('description')}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-4">
            <input
              type="number"
              name="value"
              value={newEntry.value}
              onChange={handleInputChange}
              placeholder={t('value')}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-4">
            <input
              type="number"
              name="amortization"
              value={newEntry.amortization}
              onChange={handleInputChange}
              placeholder={t('amortization')}
              required
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          {editingId ? t('update_entry') : t('add_entry')}
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">{t('date')}</th>
              <th className="px-4 py-2">{t('description')}</th>
              <th className="px-4 py-2">{t('value')}</th>
              <th className="px-4 py-2">{t('amortization')}</th>
              <th className="px-4 py-2">{t('current_value')}</th>
              <th className="px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry) => (
              <tr key={entry._id} className="border-b">
                <td className="px-4 py-2">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="px-4 py-2">{entry.description}</td>
                <td className="px-4 py-2">{entry.value}</td>
                <td className="px-4 py-2">{entry.amortization}</td>
                <td className="px-4 py-2">{entry.currentValue.toFixed(2)}</td>
                <td className="px-4 py-2">
                  <button onClick={() => handleEdit(entry)} className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded mr-2">
                    {t('edit')}
                  </button>
                  <button onClick={() => handleDelete(entry._id)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded">
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={getAnalytics} className="mt-6 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
        {t('get_analytics')}
      </button>
      {analytics && (
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-4 rounded shadow">
            <h3 className="text-xl font-bold mb-4">{t('total_intangible_values')}</h3>
            <Bar
              data={{
                labels: [t('original_value'), t('current_value'), t('total_amortization')],
                datasets: [
                  {
                    data: [
                      analytics.totalValue.totalOriginalValue,
                      analytics.totalValue.totalCurrentValue,
                      analytics.totalValue.totalAmortization
                    ],
                    backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(255, 99, 132, 0.6)'],
                  },
                ],
              }}
            />
          </div>
          <div className="bg-white p-4 rounded shadow">
            <h3 className="text-xl font-bold mb-4">{t('intangible_value_over_time')}</h3>
            <Line
              data={{
                labels: analytics.valueOverTime.map(item => item._id),
                datasets: [
                  {
                    label: t('original_value'),
                    data: analytics.valueOverTime.map(item => item.totalValue),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false,
                  },
                  {
                    label: t('current_value'),
                    data: analytics.valueOverTime.map(item => item.totalCurrentValue),
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false,
                  },
                ],
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default IntangibleDocuments;