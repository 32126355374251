import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const CreditorsBook = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    creditorName: '',
    description: '',
    amountDue: '',
    amountPaid: '',
    paymentSchedule: '',
    partialPayment: ''
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [scheduledPayments, setScheduledPayments] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  

  const fetchEntries = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/creditors`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchEntries();
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      const data = editingEntry ? editingEntry : newEntry;
      const method = editingEntry ? 'put' : 'post';
      const url = editingEntry 
        ? `${API_BASE_URL}/api/creditors/${editingEntry._id}`
        : `${API_BASE_URL}/api/creditors`;

      // Include partial payment in the request
      const requestData = { 
        ...data, 
        cooperativeId,
        partialPayment: parseFloat(data.partialPayment) || 0
      };

      const response = await axios[method](url, requestData);
      
      setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
      fetchEntries();
      setNewEntry({ 
        date: '', 
        creditorName: '', 
        description: '', 
        amountDue: '', 
        amountPaid: '', 
        paymentSchedule: '',
        partialPayment: ''
      });
      setEditingEntry(null);
      
      // If it's an edit and there was a partial payment, update the selected entry
      if (editingEntry && requestData.partialPayment > 0) {
        setSelectedEntry(response.data);
      }
    } catch (error) {
      setError(t('error_saving_entry'));
      console.error('Error saving entry:', error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry({...entry, partialPayment: ''});
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      setIsLoading(true);
      try {
        await axios.delete(`${API_BASE_URL}/api/creditors/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const schedulePayments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/creditors/schedule/payments?cooperativeId=${cooperativeId}`);
      setScheduledPayments(response.data);
    } catch (error) {
      setError(t('error_scheduling_payments'));
      console.error('Error scheduling payments:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const setPaymentSchedule = async (id, date) => {
    setIsLoading(true);
    try {
      await axios.post(`${API_BASE_URL}/api/creditors/schedule/payment`, { id, paymentSchedule: date, cooperativeId });
      setSuccess(t('payment_schedule_set_successfully'));
      fetchEntries();
    } catch (error) {
      setError(t('error_setting_payment_schedule'));
      console.error('Error setting payment schedule:', error);
    } finally {
      setIsLoading(false);
    }
  };

  
  const showPaymentHistory = (entry) => {
    setSelectedEntry(entry);
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">{t('creditors_book')}</h2>
      {error && (
        <div className="bg-red-100 text-red-700 p-3 mb-4 rounded-md flex justify-between items-center">
          {error}
          <button onClick={clearMessages} className="text-xl font-semibold">&times;</button>
        </div>
      )}
      {success && (
        <div className="bg-green-100 text-green-700 p-3 mb-4 rounded-md flex justify-between items-center">
          {success}
          <button onClick={clearMessages} className="text-xl font-semibold">&times;</button>
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center items-center my-4">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          <span className="ml-2 text-gray-600">{t('loading')}</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <input
          type="date"
          name="date"
          value={editingEntry ? editingEntry.date : newEntry.date}
          onChange={handleInputChange}
          required
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="text"
          name="creditorName"
          value={editingEntry ? editingEntry.creditorName : newEntry.creditorName}
          onChange={handleInputChange}
          placeholder={t('creditor_name')}
          required
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="text"
          name="description"
          value={editingEntry ? editingEntry.description : newEntry.description}
          onChange={handleInputChange}
          placeholder={t('description')}
          required
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="number"
          name="amountDue"
          value={editingEntry ? editingEntry.amountDue : newEntry.amountDue}
          onChange={handleInputChange}
          placeholder={t('amount_due')}
          required
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="number"
          name="amountPaid"
          value={editingEntry ? editingEntry.amountPaid : newEntry.amountPaid}
          onChange={handleInputChange}
          placeholder={t('amount_paid')}
          required
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="number"
          name="partialPayment"
          value={editingEntry ? editingEntry.partialPayment : newEntry.partialPayment}
          onChange={handleInputChange}
          placeholder={t('partial_payment')}
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="date"
          name="paymentSchedule"
          value={editingEntry ? editingEntry.paymentSchedule : newEntry.paymentSchedule}
          onChange={handleInputChange}
          placeholder={t('payment_schedule')}
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
        />
        <div className="sm:col-span-2 flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            {editingEntry ? t('update_entry') : t('add_entry')}
          </button>
          {editingEntry && (
            <button
              type="button"
              onClick={() => setEditingEntry(null)}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors"
            >
              {t('cancel')}
            </button>
          )}
        </div>
      </form>
      <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-sm">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('date')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('creditor_name')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('description')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('amount_due')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('amount_paid')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('balance')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('payment_schedule')}</th>
            <th className="py-2 px-4 border-b border-gray-200 text-gray-600 text-left">{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr key={entry._id} className="border-b border-gray-200 hover:bg-gray-50">
              <td className="py-2 px-4">{new Date(entry.date).toLocaleDateString()}</td>
              <td className="py-2 px-4">{entry.creditorName}</td>
              <td className="py-2 px-4">{entry.description}</td>
              <td className="py-2 px-4">{entry.amountDue.toFixed(2)}</td>
              <td className="py-2 px-4">{entry.amountPaid.toFixed(2)}</td>
              <td className="py-2 px-4">{entry.balance.toFixed(2)}</td>
              <td className="py-2 px-4">
                {entry.paymentSchedule ? (
                  new Date(entry.paymentSchedule).toLocaleDateString()
                ) : (
                  <input
                    type="date"
                    onChange={(e) => setPaymentSchedule(entry._id, e.target.value)}
                    className="p-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                  />
                )}
              </td>
              <td className="py-2 px-4">
                <div className="flex space-x-2">
                  <button
                     onClick={() => handleEdit(entry)}
                     className="bg-yellow-400 text-white px-2 py-1 rounded-md hover:bg-yellow-500 transition-colors"
                  >
                    {t('edit')}
                  </button>
                  <button
                     onClick={() => handleDelete(entry._id)}
                     className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition-colors"
                  >
                    {t('delete')}
                  </button>
                  <button
                     onClick={() =>   showPaymentHistory(entry)}
                     className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    {t('show_history')}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={schedulePayments}
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
      >
        {t('view_scheduled_payments')}
      </button>
      {scheduledPayments.length > 0 && (
        <div className="mt-6 p-4 bg-gray-100 rounded-md shadow-sm">
          <h3 className="text-lg font-medium text-gray-800 mb-4">{t('scheduled_payments')}</h3>
          <ul className="space-y-2">
            {scheduledPayments.map((payment) => (
              <li key={payment._id} className="text-gray-700">
                {payment.creditorName}: {payment.balance.toFixed(2)} - {t('due')}:{' '}
                {new Date(payment.paymentSchedule).toLocaleDateString()}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedEntry && (
        <div className="mt-6 bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded">
          <h3 className="font-bold mb-2">{t('payment_history_for')} {selectedEntry.creditorName}</h3>
          {selectedEntry.paymentHistory && selectedEntry.paymentHistory.length > 0 ? (
            <ul>
              {selectedEntry.paymentHistory.map((payment, index) => (
                <li key={index}>
                  {new Date(payment.date).toLocaleDateString()}: {payment.amount.toFixed(2)}
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('no_payment_history')}</p>
          )}
          <button 
            onClick={() => setSelectedEntry(null)}
            className="mt-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            {t('close')}
          </button>
        </div>
      )}
    </div>
  );
};
export default CreditorsBook;